var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('b-card',{staticClass:"p-2"},[_c('validation-observer',{ref:"rules",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('b-row',{staticClass:"mb-4"},[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-avatar',{staticClass:"badge-minimal",attrs:{"size":"80","variant":"light-primary","text":_vm.nameAbbreviation}}),_c('h1',{staticClass:"font-weight-bolder my-0 ml-2"},[_vm._v(" "+_vm._s(_vm.user.firstname)+" "+_vm._s(_vm.user.lastname)+" ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Users.Username'),"label-for":"username"}},[_c('b-form-input',{attrs:{"id":"username","value":_vm.username,"disabled":true,"placeholder":_vm.$t('Global.Write', {
                subject: _vm.$t('Management.Users.Username'),
              })}})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Users.Firstname'),"label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Users.Firstname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","disabled":!_vm.$can(_vm.$acl.action.Create, _vm.$acl.subjects.Users),"placeholder":_vm.$t('Global.Write', {
                  subject: _vm.$t('Management.Users.Firstname'),
                }),"state":errors.length > 0 ? false : null,"autocomplete":"off"},model:{value:(_vm.user.firstname),callback:function ($$v) {_vm.$set(_vm.user, "firstname", $$v)},expression:"user.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1794038889)})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Users.Lastname'),"label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Users.Lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","disabled":!_vm.$can(_vm.$acl.action.Create, _vm.$acl.subjects.Users),"placeholder":_vm.$t('Global.Write', {
                  subject: _vm.$t('Management.Users.Lastname'),
                }),"state":errors.length > 0 ? false : null,"autocomplete":"off"},model:{value:(_vm.user.lastname),callback:function ($$v) {_vm.$set(_vm.user, "lastname", $$v)},expression:"user.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2271193049)})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Users.EmployeeNumber'),"label-for":"employeeNumber"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Users.EmployeeNumber'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"employeeNumber","type":"text","placeholder":_vm.$t('Global.Write', {
                  subject: _vm.$t('Management.Users.EmployeeNumber'),
                }),"state":errors.length > 0 ? false : null,"autocomplete":"off"},model:{value:(_vm.user.employeeNumber),callback:function ($$v) {_vm.$set(_vm.user, "employeeNumber", $$v)},expression:"user.employeeNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,526234931)})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Users.Role'),"label-for":"role"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Users.Role'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{class:{
                invalid: errors.length > 0,
              },attrs:{"id":"role","disabled":!_vm.$can(_vm.$acl.action.Create, _vm.$acl.subjects.Users),"clearable":false,"placeholder":_vm.$t('Global.Select', { subject: _vm.$t('Management.Users.Role') }),"options":_vm.Roles},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var label = ref.label;
return [_c('div',[_vm._v(_vm._s(_vm.$t(("Auth.Roles." + label))))])]}},{key:"selected-option",fn:function(ref){
              var label = ref.label;
return [_c('div',[_vm._v(_vm._s(_vm.$t(("Auth.Roles." + label))))])]}}],null,true),model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1158491346)})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Users.Email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management.Users.Email'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":_vm.$t('Global.Write', {
                  subject: _vm.$t('Management.Users.Email'),
                }),"state":errors.length > 0 ? false : null,"autocomplete":"off"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3765183949)})],1)],1)],1),_c('b-row',{staticClass:"mt-5"},[(_vm.$can(_vm.$acl.action.Update, _vm.$acl.subjects.Users))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Global.Save'))+" ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],class:{ 'ml-3': _vm.$can(_vm.$acl.action.Update, _vm.$acl.subjects.Users) },attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.push({ name: 'users' })}}},[_vm._v(" "+_vm._s(_vm.$t('Global.Retreat'))+" ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }