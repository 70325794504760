<template>
  <b-card v-if="user" class="p-2">
    <validation-observer ref="rules" tag="form" @submit.prevent="submit">
      <b-row class="mb-4">
        <b-col lg="12" md="12" sm="12" class="d-flex align-items-center">
          <b-avatar
            size="80"
            variant="light-primary"
            class="badge-minimal"
            :text="nameAbbreviation"
          />
          <h1 class="font-weight-bolder my-0 ml-2">
            {{ user.firstname }} {{ user.lastname }}
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" md="4" sm="12">
          <b-form-group
            :label="$t('Management.Users.Username')"
            label-for="username"
          >
            <b-form-input
              id="username"
              :value="username"
              :disabled="true"
              :placeholder="
                $t('Global.Write', {
                  subject: $t('Management.Users.Username'),
                })
              "
            />
          </b-form-group>
        </b-col>
        <b-col lg="4" md="4" sm="12">
          <b-form-group
            :label="$t('Management.Users.Firstname')"
            label-for="firstname"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Management.Users.Firstname')"
              rules="required"
            >
              <b-form-input
                id="firstname"
                v-model="user.firstname"
                :disabled="!$can($acl.action.Create, $acl.subjects.Users)"
                :placeholder="
                  $t('Global.Write', {
                    subject: $t('Management.Users.Firstname'),
                  })
                "
                :state="errors.length > 0 ? false : null"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="4" sm="12">
          <b-form-group
            :label="$t('Management.Users.Lastname')"
            label-for="lastname"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Management.Users.Lastname')"
              rules="required"
            >
              <b-form-input
                id="lastname"
                v-model="user.lastname"
                :disabled="!$can($acl.action.Create, $acl.subjects.Users)"
                :placeholder="
                  $t('Global.Write', {
                    subject: $t('Management.Users.Lastname'),
                  })
                "
                :state="errors.length > 0 ? false : null"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="4" sm="12">
          <b-form-group
            :label="$t('Management.Users.EmployeeNumber')"
            label-for="employeeNumber"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Management.Users.EmployeeNumber')"
              rules="required|integer"
            >
              <b-form-input
                id="employeeNumber"
                v-model="user.employeeNumber"
                type="text"
                :placeholder="
                  $t('Global.Write', {
                    subject: $t('Management.Users.EmployeeNumber'),
                  })
                "
                :state="errors.length > 0 ? false : null"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="4" sm="12">
          <b-form-group :label="$t('Management.Users.Role')" label-for="role">
            <validation-provider
              #default="{ errors }"
              :name="$t('Management.Users.Role')"
              rules="required"
            >
              <v-select
                id="role"
                v-model="user.role"
                :disabled="!$can($acl.action.Create, $acl.subjects.Users)"
                :clearable="false"
                :placeholder="
                  $t('Global.Select', { subject: $t('Management.Users.Role') })
                "
                :class="{
                  invalid: errors.length > 0,
                }"
                :options="Roles"
              >
                <template #option="{ label }">
                  <div>{{ $t(`Auth.Roles.${label}`) }}</div>
                </template>
                <template #selected-option="{ label }">
                  <div>{{ $t(`Auth.Roles.${label}`) }}</div>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="4" sm="12">
          <b-form-group :label="$t('Management.Users.Email')" label-for="email">
            <validation-provider
              #default="{ errors }"
              :name="$t('Management.Users.Email')"
              rules="email"
            >
              <b-form-input
                id="email"
                v-model="user.email"
                :placeholder="
                  $t('Global.Write', {
                    subject: $t('Management.Users.Email'),
                  })
                "
                :state="errors.length > 0 ? false : null"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-button
          v-if="$can($acl.action.Update, $acl.subjects.Users)"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
        >
          {{ $t('Global.Save') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          :class="{ 'ml-3': $can($acl.action.Update, $acl.subjects.Users) }"
          @click="$router.push({ name: 'users' })"
        >
          {{ $t('Global.Retreat') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BFormInput,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer } from '@validations'

import { preventUnsavedChangesRouteLevel } from '@/utility/mixins/preventUnsavedChanges'
import { Roles } from '@/data/enums'
import { updateAbility } from '@/libs/acl/ability'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    vSelect,
    BAvatar,
    BFormInput,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },

  mixins: [preventUnsavedChangesRouteLevel],

  data: () => ({
    user: null,

    Roles,
    required,
    email,
    integer,
  }),

  computed: {
    username() {
      if (!this.user) return ''
      return this.nameAbbreviation + this.user.employeeNumber
    },
    nameAbbreviation() {
      if (!this.user) return ''
      return this.user.firstname.charAt(0) + this.user.lastname.charAt(0)
    },
  },

  async created() {
    try {
      const { data } = await this.$axios.get(`users/${this.$route.params.id}`)
      this.user = data
    } catch (error) {
      this.$alert.error()
      this.$router.push({ name: 'users' })
    }
  },

  methods: {
    async submit() {
      const valid = await this.$refs.rules.validate()

      if (valid) {
        const user = { ...this.user }
        user.employeeNumber = parseInt(user.employeeNumber, 10)
        user.username = this.username
        user.email = !user.email ? null : user.email
        try {
          const { data } = await this.$axios.patch(
            `users/${this.user.id}`,
            user,
          )
          this.$alert.update(`${data.firstname} ${data.lastname}`)
          if (data.id === getUserData().id) {
            this.$ability.update(updateAbility(data.role))
          }
          this.dirty = false
          this.$router.push({ name: 'users' })
        } catch (error) {
          if (error.message === 'Request failed with status code 409') {
            this.$alert.error(this.$t('Management.Users.UsernameTaken'))
            return
          }
          this.$alert.error()
        }
        return
      }
      this.$alert.invalid()
    },
  },
}
</script>
